export class ConfigService {


    private static GetLocataireId() {
        return  ConfigService.GetLocataireIdFromQuery() ?? 
                ConfigService.GetLocataireIdFromVariableEnv() ?? 
                ConfigService.GetLocataireIdFromDomainName() 
    }

    private static GetLocataireIdFromVariableEnv(){
        if ((window as any)._env_.LOCATAIRE_ID !== '') {
            return (window as any)._env_.LOCATAIRE_ID;
        }
        return null;
    }

    private static GetLocataireIdFromDomainName(){
        var hostname = window.location.hostname;
        // Divise l'hôte par les points
        const parts = hostname.split('.');
        // Si l'hôte contient plus de deux parties, cela signifie qu'il y a un sous-domaine
        if (parts.length > 2 && hostname != "127.0.0.1") {
            // Retourne le premier élément comme sous-domaine
            return parts[0].replace("https://", '');
        }
        return null;
    }

    private static GetLocataireIdFromQuery(){
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        return params.get('locataireId') || null;
    }

    public static Get(): ApiConfiguration {
        return <ApiConfiguration>{
            API_HOST: (window as any)._env_.REACT_APP_API_HOST,
            LOCATAIRE_ID: ConfigService.GetLocataireId(),
            USE_FAKE_FILLED_FORM: (window as any)._env_.USE_FAKE_FILLED_FORM
        }
    }

}
