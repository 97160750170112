
import { AppConfiguration } from "../../domain/entities/AppConfiguration";
import { Prestation } from "../../domain/entities/Prestation";
import { IAppConfigurationRepository } from "../../domain/interfaces/IAppConfigurationRepository";
import { HttpClient } from "./HttpClient";

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}



export class AppConfigurationRepositoryRemote implements IAppConfigurationRepository {
  
  private httpClient: HttpClient;

  constructor() {
    this.httpClient = new HttpClient();
  }

  private  GetProspectKeyFromQuery(){
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const pKey = params.get('pKey');
    return  pKey != null ? `?pKey=${params.get('pKey')}` : '';
}
  async GetAppConfiguration(): Promise<AppConfiguration | null> {
    const endpoint = `/api/configurations${this.GetProspectKeyFromQuery()}`;
    try {
      const communes = await this.httpClient.get<AppConfiguration>(endpoint)
      return communes;
    } catch (error) {
      console.error('Erreur lors de la récupération des prestations:', error);
     throw error;
    }
  }
}


